<template>
  <div class="row">
    <div class="col-12 col-md-6">
      <div class="input-group">
        <input class="form-control"
               id="search-txt"
               name="search-txt"
               type="text"
               placeholder="Search"
               autocomplete="off"
               v-model="filterText"
               @keyup.enter="doFilter" />
        <div class="input-group-append">
          <button class="btn btn-primary" type="submit" @click="doFilter">
            <i class="fas fa-search"></i>
          </button>
        </div>
        <button class="btn btn-outline-primary ml-2" @click="resetFilter">
          <i class="fas fa-sync-alt"></i>
        </button>
        <button class="btn btn-outline-success btn-sm ml-2"
                @click="updateList">
          Update List
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name : 'FilterBar',
    data() {
      return {
        filterText : '',
      }
    },
    methods : {

      /**
       * Filter Action
       */
      doFilter() {
        this.$events.fire('filter-set', this.filterText);
      },

      /**
       * Reset Filter
       */
      resetFilter() {
        this.filterText = '';
        this.$events.fire('filter-reset');
      },

      /**
       * Update Broadcast List
       */
      updateList() {
        this.$emit('update-list');
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../../assets/scss/components/admin/broadcast/filter-bar";
</style>
